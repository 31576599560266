import React from "react";
import styles from './Home.module.css';
import img1 from './QQ20180528-092009@2x.png'

export const HomePaper = () => <>
    <div className={styles.container}>
        <div className={styles.img_container}>
            <img className={styles.img} src={img1} alt="小程序使用场景"/>
        </div>
        <div style={{flex: 1}}/>
        <div>
            微信公众号/小程序开发 <br/>
            电话: 18121629620 <br/>
            微信: 18121629620 <br/>
            地址: 东海县牛山镇 <br/>
        </div>
    </div>
    <div className={styles.footer}>
        <p>
            &copy;东海县远景软件开发有限公司&nbsp;版权所有<br/>
            <a href={"//beian.miit.gov.cn"} target="_blank" rel="noopener noreferrer">苏ICP备14041869号-1 </a>
        </p>
    </div>
</>;
