import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import styles from "./AppListContainer.module.css";
import {appService} from "../services";

const AppListContainer = () => {
    const [apps, setApps] = useState(appService.getList().reverse());
    useEffect(() => {
        const run = async () => {
            const response = await fetch('http://daohang.binbinsoft.com/widget/json?name=lotosbin&pageSize=100');
            const result = await response.json();
            setApps(result);
        };
        // noinspection JSIgnoredPromiseFromCall
        run();
    }, []);
    return (
        <div className={styles.container}>
            {apps.map(it => <div key={it.Id} className={styles.item}><Link to={`app/${encodeURIComponent(it.Url)}`}>{it.Name}</Link></div>)}
        </div>
    );
};

export default AppListContainer;
