class AppService {

    getList() {
        return [{"Name": "东海县创新工场", "Url": "http://diy.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "52c39a1b84ec4e1240bdeaab"}, {"Name": "东海水晶网", "Url": "http://shuijing.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "52c39a3b84ec4e1240bdeaac"}, {"Name": "导航网", "Url": "http://daohang.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "52c39a4f84ec4e1240bdeaad"}, {"Name": "歌谱网", "Url": "http://gepu.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "52c39dc684ec4e12409b0f51"}, {"Name": "菜谱网", "Url": "http://caipu.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "52c3f13b84ec4e0f7c805fa6"}, {
            "Name": "远景推广",
            "Url": "http://tuiguang.binbinsoft.com",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "52d6b61984ec4e094cc892d5"
        }, {"Name": "打印啦", "Url": "http://www.dayin.la/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "530d265684ec4e1250684527"}, {"Name": "我爱3d", "Url": "http://www.woi3d.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "530d26b884ec4e1250684528"}, {"Name": "金句", "Url": "http://jinju.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "530d2be284ec4e07a8d58024"}, {"Name": "小提示", "Url": "http://tips.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "530d2c0484ec4e07a8d58025"}, {"Name": "每日趣图", "Url": "http://qutu.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "530eefed84ec4e0fd8b776e3"}, {"Name": "3D打印模型搜索引擎", "Url": "http://www.yeggi.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5312e69384ec4e04d8f70d22"}, {
            "Name": "3d模型分享网站",
            "Url": "http://www.thingiverse.com/",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "53195a4384ec4e0a78c0081d"
        }, {"Name": "每日笑话", "Url": "http://joke.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "531d80f084ec4e0d04ea5cf9"}, {"Name": "git ignore", "Url": "http://www.gitignore.io/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5321151984ec4e0f8478e532"}, {"Name": "赚800", "Url": "http://www.z800app.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "58329814b596b2131cc6b0c4"}, {"Name": "赚800", "Url": "http://www.zuanke8.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "58329affb596b2131cc6b0c5"}, {"Name": "赚800", "Url": "http://www.zuanke8.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "58329b07b596b2131cc6b0c6"}, {"Name": "http://www.zuanke8.com/", "Url": "http://www.zuanke8.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "58329b17b596b2131cc6b0c7"}, {
            "Name": null,
            "Url": "http://www.blazehot.com/",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "58329d10b596b2131cc6b0c8"
        }, {"Name": "http://www.blazehot.com/", "Url": "http://www.blazehot.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "58329d20b596b2131cc6b0c9"}, {"Name": "daohang-apphb", "Url": "http://daohang.apphb.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5a0e3ff7b596b20858954470"}, {"Name": "笑话2", "Url": "http://joke2.binbinsoft.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5a0e4db1b596b2136cd93e21"}, {"Name": "redmine", "Url": "http://redmine.ishangban.com/redmine/issues?query_id=20", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5af3bf83b596b21204c8d9d9"}, {"Name": null, "Url": "http://read.yuanjingtech.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5af3d43db596b214683f6e84"}, {
            "Name": "read",
            "Url": "http://read.yuanjingtech.com",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "5af3d559b596b214683f6e85"
        }, {"Name": null, "Url": "http://redmine.ishangban.com/redmine/issues/gantt", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5af3ed80b596b214683f6e86"}, {"Name": null, "Url": "http://redmine.ishangban.com/redmine/issues/gantt", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5af3eda5b596b214683f6e87"}, {"Name": null, "Url": "http://redmine.ishangban.com/redmine/issues/gantt", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5af3edb1b596b214683f6e88"}, {"Name": null, "Url": "http://read.yuanjingtech.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5b2a05ffb596b20960ef8588"}, {"Name": null, "Url": "http://union.yuanjingtech.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5b2a7756b596b20e78e30f3a"}, {
            "Name": "union",
            "Url": "http://union.yuanjingtech.com",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "5b2a779cb596b20e78e30f3b"
        }, {"Name": "bshare", "Url": "http://www.bshare.cn/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5b2b0db3b596b21a603d2a69"}, {"Name": "xRedmine", "Url": "http://118.190.32.118:32772/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5b968c27b596b27c9ce08c33"}, {"Name": "电鸭社区", "Url": "https://eleduck.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98db121615804948e7e08"}, {"Name": null, "Url": "https://lab.lalkk.com/fun/du/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98f8021615804948e7e09"}, {"Name": "仙人球调查", "Url": "https://xrq360.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98f9721615804948e7e0a"}, {"Name": "V2EX", "Url": "https://www.v2ex.com/?r=lotosbin", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98faa21615804948e7e0b"}, {
            "Name": "lotosbin\u0027s blog",
            "Url": "https://lotosbin.github.io/",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "5de98fbc21615804948e7e0c"
        }, {"Name": null, "Url": "https://xread-web.now.sh/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98fe321615804948e7e0d"}, {"Name": null, "Url": "http://youhui.yuanjingtech.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de98ff321615804948e7e0e"}, {"Name": null, "Url": "http://youhui.yuanjingtech.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9901921615804948e7e0f"}, {"Name": "有个优惠券", "Url": "http://youhui.yuanjingtech.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9902a21615804948e7e10"}, {"Name": "毒鸡汤", "Url": "https://lab.lalkk.com/fun/du/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9904621615804948e7e11"}, {"Name": "仙人球调查", "Url": "https://xrq360.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9905f21615804948e7e12"}, {
            "Name": "来阅读",
            "Url": "https://xread-web.now.sh/",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "5de9907521615804948e7e13"
        }, {"Name": null, "Url": "http://joke.yuanjingtech.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9908a21615804948e7e14"}, {"Name": "笑话", "Url": "http://joke.yuanjingtech.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5de9909421615804948e7e15"}, {"Name": "nicetool", "Url": "http://www.nicetool.net/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5df3731a2161580f18477071"}, {"Name": "舒尔特方格", "Url": "http://www.4399.com/flash/84610_3.htm", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5df373bf2161580f18477072"}, {"Name": "integromat", "Url": "https://www.integromat.com", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5df5a7b22161580bb009d53e"}, {"Name": "一起做副业", "Url": "https://17zfy.ygcf.info/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5e00bcdf2161580a3c7167c4"}, {
            "Name": "果汁实验室",
            "Url": "http://guozhivip.com/lab/",
            "UserValue": {"Name": "lotosbin"},
            "WebsiteId": 0,
            "Industries": [],
            "Id": "5e00c0f82161580a3c7167c5"
        }, {"Name": "今日诗词", "Url": "https://www.jinrishici.com/", "UserValue": {"Name": "lotosbin"}, "WebsiteId": 0, "Industries": [], "Id": "5e036ffa21615813f019c862"}];
    }
}

export const appService = new AppService();
