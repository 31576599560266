import React from 'react';
import gql from "graphql-tag";
import {makeStyles} from "@material-ui/core";
import styles from './Group.module.css';
import {useQuery} from "react-apollo-hooks";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

let query = gql`query groups {
    groups(page:{last:10}) {
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        edges{
            cursor
            node{
                qrcode
                expireTime
            }
        }
    }
}
`;
const Group = () => {
    const classes = useStyles();
    let variables = {page: {last: 10}};
    const {data, fetchMore, refetch, loading, error} = useQuery(query, {variables});
    if (loading) return (<Typography component="p">{('加载中')}...</Typography>);
    if (error) return (<Typography component="p">{'出错啦'} !!!</Typography>);
    let tiles = data.groups.edges.map((it: { node: any; }) => it.node);
    return (
        <div className={styles.container}>
            {tiles.map((tile: { qrcode: string | undefined; }) => (
                <div className={styles.item} onClick={e => window.open(tile.qrcode)}>
                    <img className={styles.img} src={tile.qrcode}/>
                </div>
            ))}
        </div>
    );
};
export default Group;
