import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import Iframe from 'react-iframe'
import {inspect} from "util";
import styles from "./AppContainer.module.css"
import {appService} from "../services";

const AppContainer = () => {
    let {params: {url}} = useRouteMatch({path: '/app/:url', strict: true, sensitive: true});
    const [apps, setApps] = useState(appService.getList().reverse());
    useEffect(() => {
        const run = async () => {
            const response = await fetch('http://daohang.binbinsoft.com/widget/json?name=lotosbin&pageSize=100');
            const result = await response.json();
            setApps(result);
        };
        // noinspection JSIgnoredPromiseFromCall
        run();
    }, []);
    let urlDecoded = decodeURIComponent(url);
    return <div className={styles.container}>
        <div className={styles.left}>
            {apps.map(it => <Link key={it.Id} to={`/app/${encodeURIComponent(it.Url)}`}>{it.Name}</Link>)}
        </div>
        <div className={styles.right}>
            <div><a href={urlDecoded} target="_blank" rel="noopener noreferrer">{urlDecoded}</a></div>
            <Iframe
                url={urlDecoded}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
        </div>
    </div>;
};

export default AppContainer;
