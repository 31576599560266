import React from 'react';
import styles from "./App.module.css";
import {HashRouter as Router, Route} from "react-router-dom";
import {HomePaper} from "./components/Home/HomePaper";
import AppBar from "@material-ui/core/AppBar";
import blue from '@material-ui/core/colors/blue';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import Group from "./Group";
import client from './apollo/client';
import {ApolloProvider} from "react-apollo";
import {ApolloProvider as ApolloHooksProvider} from 'react-apollo-hooks';
import AppListContainer from "./modules/app/containers/AppListContainer";
import AppContainer from "./modules/app/containers/AppContainer";
const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
});
const App = () => (
    <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <div className={styles.app}>
                        <AppBar position="static">
                            <Toolbar>
                                <IconButton color="inherit" aria-label="Menu">
                                    <MenuIcon/>
                                </IconButton>
                                <Typography variant="h6" color="inherit">
                                    远景软件
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Route exact path="/" component={HomePaper}/>
                        <Route path="/group" component={Group}/>
                        <Route path="/apps" component={AppListContainer}/>
                        <Route path="/app/:url" component={AppContainer}/>
                    </div>
                </MuiThemeProvider>
            </Router>
        </ApolloHooksProvider>
    </ApolloProvider>
);

export default App;
