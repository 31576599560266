import {HttpLink} from 'apollo-link-http';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';

// Create an http link:
const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
});
export default client;
